import React from 'react'
import ButtonAppBar from '../components/WhatsAppComp/WhatsAppComp';
import { MainDiv } from './style';

const About = () => {
  return (
    <MainDiv>
    

    </MainDiv>
  )
}

export default About;

import styled from "styled-components";


export const MainDiv = styled.div`
  width: 100%;
  height: 500px;
  align-items: center;
  text-align: center;
  margin: 5% 0;
   
  @media (max-width: 900px) {
    height: 650px;
    margin: 15% 0;
  }
}
`;
export const IframeDiv = styled.iframe`
  width: 40%;
  height: 75% !important;
  margin: 1%;
  border-radius: 25px;

  @media (max-width: 900px) {
    width: 90%;
    height: 45% !important;

}
}
`;

export const Title = styled.h2`
    font-size: 20px;
    color: #7084F2;
    margin: 5%;
    text-align: center;
    
    @media (max-width: 900px) {
        font-size: 15px;

    }
`;
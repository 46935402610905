import axios from 'axios';
const baseUrl = 'https://mashkanta-server.vercel.app'// 'http://localhost:8000' //This is development. 

const config = {
    headers: {
      "Access-Control-Allow-Origin": "https://www.amitmort.co.il",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      'Access-Control-Allow-Credentials': true
    }
  };

export const postRequest = (userdata) => {
    try {
        return axios.post(baseUrl+'/newCustomer', userdata).then(res => {
        if(res && res.data.error){
            return res.data;
        }else{
            return res.data.msg;
        }
        
        })
    } catch (err) {
    console.log(err)
    }
};

export const adminLogin = (userdata) => {
    try {
        return axios.post(baseUrl+'/login', userdata, config).then(res => {
        if(res && res.data.error){
            console.log(res)
            return res.data;
        }else{
            localStorage.setItem('user', JSON.stringify(res.data[0]));
            return res.data.msg;
        }
        
        }).catch(error => console.log(error));

    } catch (err) {
    console.log(err)
    }
};

export const getCustomersRequest = () => {
    try {
        return axios.get(baseUrl+'/requests', {withCredentials: true}).then(res => {
        return res.data
        })
        
    } catch (err) {
    console.log(err)
    }
};
import React, { useRef, useEffect} from 'react'
import AppNavBar from '../components/AppNavBar/AppNavBar'
import NavBar from '../components/NavBar/NavBar';
import { Text, Title, MainDiv2, List, Listitem, MainTitle, ExpMainDiv, UnderDivExp, NavbarDiv, MainDiv3} from './style';

const Explanations = () => {
  const OptionMenu1 = useRef();
  const OptionMenu2 = useRef();
  const OptionMenu3 = useRef();
  const OptionMenu4 = useRef();

  const scrollingFunc = (scroll) => {
    if(scroll == 1){
      if (OptionMenu1.current) {
        OptionMenu1.current?.scrollIntoView({ behavior: "smooth" });
      }
    }else if(scroll == 2){
      if (OptionMenu2.current) {
        OptionMenu2.current?.scrollIntoView({ behavior: "smooth" });
      }
    }else if(scroll == 3){
      if (OptionMenu3.current) {
        OptionMenu3.current?.scrollIntoView({ behavior: "smooth" });
      }
    }else if(scroll == 4){
      if (OptionMenu4.current) {
        OptionMenu4.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
    localStorage.removeItem("scrollTo");
  };
  


  const scroll = window.localStorage.getItem("scrollTo")
  useEffect(() => {
    if(scroll){
      scrollingFunc(scroll)
    }

  }, [scroll]);


  return (
    <ExpMainDiv>
    <AppNavBar scrollingFunc={scrollingFunc} />
      <UnderDivExp>
        <MainDiv2>
          <MainDiv3>
          <MainTitle ref={OptionMenu1}>מיחזור משכנתא</MainTitle>
          <Text>מיחזור משכנתא הינו סילוק של משכנתא קיימת על ידי לקיחת משכנתא חדשה. הליך המיחזור יכול לחסוך ללווה עשרות אלפי ₪, לחסוך בשנים ואף להוריד את ההחזר החודשי. על מנת לבצע מיחזור משכנתא צריך תחילה לבדוק האם ישנה כדאיות. חשוב שהבדיקה תיעשה על ידי אנשי מקצוע בלתי תלויים ומקצועיים. הליך הבדיקה נעשה אצלי ללא עלות או התחייבות מצד הלקוח.</Text>
          <Title>מדוע כדאי למחזר משכנתא?</Title>
          <Text>במקרים רבים הלווים מקבלים משכנתא בריביות גבוהות ו/או בתמהיל (הרכב) לא אטרקטיבי הגורם להם לשלם עשרות אלפי שקלים מיותרים של ריבית לבנק.
          במסגרת הליך המיחזור בונים ללווה תמהיל משכנתא שמתאים לפרופיל הפיננסי שלו תוך בחירת בחירת המסלולים הנמוכים ביותר והריביות הנמוכות ביותר. כתוצאה מכך נוצר חיסכון ללקוח של עשרות אלפי שקלים במשכנתא!
        <Title>מתי כדאי למחזר?</Title>
          </Text>
          <List>
            <Listitem>כאשר ניתן להשיג ריביות נמוכות יותר מתנאי המשכנתא המקוריים.</Listitem>
            <Listitem>כאשר ניתן לתכנן תמהיל משכנתא אטרקטיבי יותר מהתמהיל המקורי.</Listitem>
            <Listitem>כאשר רמת ההכנסה עלתה ובעקבותיה ניתן להגדיל ההחזר החודשי.</Listitem>
            <Listitem>כאשר רמת ההכנסה קטנה והלווים מעוניינים להקטין ההחזר החודשי.</Listitem>
            <Listitem>כאשר מתקבלים סכומים חד פעמיים משמעותיים כמו ירושה או קה"ש.</Listitem>
          </List>
          <Title>למי מיועד מיחזור המשכנתא ?</Title>
          <Text>המיחזור איננו מיועד רק למי שרוצה לשפר את תנאי ההלוואה עקב ירידת ריבית או רווחה כלכלית. לעיתים יש צורך לבצע מחזור כאשר יש קושי לעמוד בהחזרים החודשיים עקב הרעה במצב הכלכלי. ניתן לפרוס את ההלוואה על פני זמן ארוך יותר, על מנת להקטין את ההחזרים החודשיים. התהליך מורכב ודורש הבנה בתחום. השאירו פרטיכם באתר ואחזור אליכם בהקדם!</Text>
          <MainTitle ref={OptionMenu2}>משכנתא לרכישת דירה מקבלן/ יד 2</MainTitle>
          <Text>משכנתא זו עסקה גדולה ועבור רובכם אולי העסקה המשמעותית ביותר שתעשו במהלך ימי חייכם. בהליך זה תמצאו עצמכם מתנהלים מול הבנקים ומנהלים מולם משא ומתן על מנת לקבל משכנתא. זאת תוך שאיפה לקבל תנאים טובים מבלי שלרובכם אין מושג מה זה אומר תנאים טובים. במקביל אתם צריכים להתנהל מול המוכר או הקבלן ומול עורך הדין, כל זאת תוך המצאת ניירת רלוונטית לבנקים.
 
          כדאי לדעת שלקיחת משכנתא לרכישת דירה היא עניין יותר מורכב ממה שחלקכם משערים. אתם צריכים להיות תקינים אשראית מבחינת הבנק למשכנתאות והנכס הנרכש צריך להיות תקין מבחינה שמאית-משפטית. ההבדל בין משכנתא טובה למשכנתא לא טובה יכול להסתכם במאות אלפי שקלים של ריבית מיותרת שתשלמו לבנק למשכנתאות. ממש כך!
          חשוב לדעת שביניכם כלווים לבין הבנק המלווה קיים ניגוד אינטרסים טבעי. קרוב לוודאי שהייעוץ הניתן לכם בבנק לא יהיה חף מאינטרסים, מה שעלול להשפיע משמעותית על איכות המשכנתא.
          </Text>
          <Title>מהו ההון העצמי המינימלי הדרוש לרכישת דירה?</Title>
          <Text>הלווים יצטרכו מינימום של 25% הון עצמי על מנת לקבל משכנתא לדירה ראשונה.
          לדוגמא: לדירה שעולה 1.5 מיליון ₪ תצטרכו להביא הון עצמי מינימלי של 375,000 ₪.
          למשפרי דיור הון עצמי מינימלי נדרש הינו 30% משווי הנכס.
          </Text>
          <Title>מהם הפרמטרים העיקריים המשפיעים על תנאי המשכנתא?</Title>
          <Text>הפרמטרים העיקריים המשפיעים על תנאי המשכנתא הם:</Text>
          <List>
            <Listitem>גובה ההון העצמי – כעיקרון, ככל שההון העצמי גבוה יותר התנאים שהבנק ייתן יהיו טובים יותר.</Listitem>
            <Listitem>כושר ההשתכרות של הלווים – ככל שהפרופיל הפיננסי מרשים יותר, הריביות יהיו נמוכות יותר</Listitem>
            <Listitem>מסלולי המשכנתא – הבנקים למשכנתאות מציעים מגוון רחב של מסלולים. לבחירת מסלולי המשכנתא (תמהיל המשכנתא) יכולה להיות השפעה עצומה של עד מאות אלפי שקלים על ההחזרים המצטברים של התשלומים לבנק.</Listitem>
            <Listitem>לוח סילוקין – לאופן החזר המשכנתא (סילוק המשכנתא) יש השפעה על הריבית המצטברת שתשלמו לאורך חיי המשכנתא.</Listitem>
            <Listitem>מכרז מול הבנקים – תנאי המשכנתא שתקבלו סביר להניח שישתנו בין הבנקים השונים בישראל. יותר מזה, ייתכן שאם תכנסו לסניפים שונים של אותו בנק, תקבלו הצעות שונות. על מנת למצות את התהליך ולקבל את התנאים הטובים ביותר יש לבצע מכרז מקצועי בין מספר בנקים.</Listitem>
            <Listitem>יועץ משכנתאות – יועץ משכנתאות מקצועי יכול להשיג לכם תנאים טובים יותר ממה שתוכלו להשיג בכוחות עצמכם. תנאים טובים יותר פירושם חיסכון של עד מאות אלפי שקלים של ריבית שהייתם צריכים לשלם לאורך חיי המשכנתא!
            </Listitem>
          </List>
          <Title>מהו אישור עקרוני להלוואת משכנתא?</Title>
          <Text>אישור עקרוני זהו אישור מודפס שהבנק למשכנתאות אליו פניתם נתן לכם. באישור זה רשום בין השאר גובה המשכנתא, מסלולי המשכנתא וריביות המשכנתא. חשוב לדעת שהבנק יכול לבטל אישור עקרוני בכל רגע נתון. כמו כן הריביות הנקובות באישור העקרוני תקיפות ל- 24 יום. אם לא הצלחתם לבצע את התהליך תוך פרק זמן של 24 יום, מיום קבלת האישור העקרוני, הבנק לא מחויב לתנאים.</Text>
          <Title> מהו גובה ההכנסה המינימלי שהלווים צריכים להשתכר על מנת לקבל משכנתא?</Title>
          <Text>ראשית צריך לדעת שהבנק מודד את כושר ההשתכרות של הלווים במונחי נטו- לאחר מס וכן לאחר ניכוי החזרי הלוואות (עם התחשבות בזמן סיום ההלוואות). הדבר החשוב שצריך להבין הוא שההחזר החודשי של המשכנתא לא יכול להיות גבוה משליש מההכנסה הפנויה נטו. לפעמים הבנק יכול לאשר יחס גבוה יותר (עד כ- 40%) תלוי בעסקה, באיכות הלווה ובבנק למשכנתאות. </Text>
          <Title> מהן עלויות הכרוכות בלקיחת משכנתא?</Title>
          <Text>העלויות העיקריות הכרוכות בהליך לקיחת משכנתא: שכר טרחת עורך דין, שמאות על הנכס, דמי פתיחת תיק (משולמים ישירות לבנק), רישום בטאבו ורשם משכונות, יעוץ המשכנתא, תיווך וכו'</Text>
          <Title> מהם המסמכים הדרושים על מנת להגיש בקשה למשכנתא?</Title>
          <Text>המסמכים הדרושים לקבלת המשכנתא משתנים לפי סוג הלווה. יש הבדל בין שכיר, עוסק מורשה ובעל שליטה בחברה לגבי סוג המסמכים שצריך להמציא לבנק.</Text>
          <Title>איך בוחרים יועץ משכנתאות מקצועי?</Title>
          <Text>מומלץ יועץ משכנתאות שעבד בעבר באחד מהבנקים למשכנתאות ככה שיש לו את הקשרים והניסיון הדרוש בתהליך, יועץ שמורשה לעבוד מול הבנקים, אחד כזה יכול לייצג אתכם מול הבנק (כמו עורך דין שלכם). כלומר לא תצטרכו להגיע יחד איתו לבנקים ולהפסיד זמן וימי עבודה. ליועץ מורשה יש כתבי הסמכה של כל בנק למשכנתאות הוא מחתים אותכם דיגיטלית/ידנית על כתב ההסמכה ובעצם נתתם לו סמכות (יפוי כוח) לטפל לכם בנושא הזה בלבד. בנוסף מומלץ לבדוק את הרזומה של אותו יועץ שמטפל בכם. יועץ משכנתאות טוב יכול לחסוך לכם עד מאות אלפי שקלים! </Text>
          <Title>מה אמור לכלול שירות של יועץ ?</Title>
          <Text>לצד החיסכון הפיננסי יועץ טוב יודע לייעץ ללקוח מהי הדרך הנכונה ביותר להיכנס לעסקה וכיצד לבצע אותה על הצד הטוב ביותר. הליווי אמור להסתיים בעת קבלת המשכנתא. 
          השירות כולל:
          </Text>
          <List>
            <Listitem>אפיון הלקוח ואפיון העסקה</Listitem>
            <Listitem>בניית תמהיל משכנתא המתאים לפרופיל הפיננסי של הלווים</Listitem>
            <Listitem>ביצוע מכרז מול הבנקים למשכנתאות עד להשגת התנאים הטובים ביותר</Listitem>
            <Listitem>ייעוץ וליווי אישי עד לחתימה בבנק וקבלת המשכנתא</Listitem>
            <Listitem>חיסכון של עד מאות אלפי שקלים</Listitem>
          </List>
          <Title>במידה ואתם זקוקים למידע נוסף ניתן לפנות אליי ללא כל התחייבות. התקשרו או השאירו פרטים באתר ואחזור אליכם בהקדם.</Title>
          <MainTitle ref={OptionMenu3}>משכנתא לכל מטרה</MainTitle>
          <Text>משכנתא לכל מטרה הינה משכנתא הנלקחת כנגד שיעבוד נכס קיים. בעבר הבנקים למשכנתאות התעניינו פחות מה מטרת ההלוואה. היום סיבת ההלוואה הינה מהותית. לכן לא תמיד הבנק למשכנתאות יאשר הלוואה כזו. למשל משכנתא לכל מטרה לצורך עסקי לא תאושר על ידי הבנק.
          הבנק נותן הלוואה לכל מטרה עד 50% משווי הבית לפי הערכת שמאי ולאחר ניכוי המשכנתא. לעתים ניתן להשיג אחוז מימון גבוה יותר, כלומר מימון מעל 50% משווי הנכס.
          </Text>
          <Title>מהן המטרות אותן הבנק יראה כלגיטימיות?</Title>
          <List>
            <Listitem>רכישת נכס נוסף</Listitem>
            <Listitem>עזרה לילדים ברכישת דירה</Listitem>
            <Listitem>שיפוצים בלי הבירוקרטיה של תהליך השיפוץ</Listitem>
            <Listitem>החזר חובות</Listitem>
            <Listitem>כיסוי הלוואות</Listitem>
          </List>
          <Title>מהם המסמכים אותם צריך להמציא לבנק?</Title>
          <Text>המסמכים הדרושים לקבלת משכנתא זהים למסמכים הנדרשים לקבלת משכנתא לרכישת נכס.</Text>
          <Title>מה קורה אם יש כבר משכנתא על הדירה?</Title>
          <Text>ניתן לקבל משכנתא לכל מטרה עד 50% משווי בית קיים. כאשר יש משכנתא קיימת, ניתן יהיה לקבל עד 50% מהשווי בהתחשב במשכנתא הקיימת. דוגמא: יש לכם נכס בשווי של 1.5 מיליון ₪. זה אומר שתוכלו לקבל עליו מכסימום 750,000 ₪. במידה ורובצת על הנכס משכנתא של 500,000 ₪, תוכלו לקבל עוד 250,000 ₪ נוספים.</Text>
          <Title>מה גובה הריביות?</Title>
          <Text>הריביות במשכנתא לכל מטרה תהיינה גבוהות יותר מריביות למשכנתא לדיור. ההפרשים בגובה הריביות בין משכנתא לדיור למשכנתא לכל מטרה יכולים להגיע ל- 3% ויותר. זה תלוי בין השאר בתמהיל המשכנתא ומה מטרת ההלוואה.</Text>
          <Title>מה היתרון של הלוואה כזו לעומת הלוואה רגילה בבנק?</Title>
          <List>
            <Listitem>פריסה נוחה של ההלוואה, לתקופה ארוכה</Listitem>
            <Listitem>לרוב תנאי ריבית טובים יותר לעמת הלוואה רגילה</Listitem>
            <Listitem>החזרים חודשיים נמוכים יותר</Listitem>
            <Listitem>ניתן לקבל סכום גבוה יותר, עקב שיעבוד נכס</Listitem>
            <Listitem>הלוואה שלא חונקת את האובליגו</Listitem>
          </List>
          <Title>האם יש צורך בשמאי?</Title>
          <Text>בוודאי. למרות שעשיתם בעבר שמאי, סכום המשכנתא משתנה, שווי הדירה השתנה. לכן יש צורך בשמאות מחודשת על הנכס. </Text>
          <Title>מה גובה המימון המקסימלי אותו ניתן לקבל?</Title>
          <Text>גובה המימון המקסימלי אותו ניתן לקבל הוא 50% משווי הדירה הקיימת. אם כי קיימים מקרים בהם ניתן לקבל מימון של 60% משווי הנכס ואף יותר. </Text>
          <Title>האם מטרת ההלוואה חשובה?</Title>
          <Text>בוודאי, חשובה מאוד. מטרת ההלוואה יכולה לקבוע האם ההלוואה תאושר או לא. למשל בקשת משכנתא לכל מטרה לצורך כיסוי חובות של העסק, לא תאושר על ידי הבנק למשכנתאות.
          דבר נוסף, אם מטרת ההלוואה הינה לדיור, כלומר לרכישת נכס נוסף, סביר להניח שניתן לבקש ריביות נמוכות יותר מאשר הלוואה למטרת סגירת חובות .</Text>
          <Text>גם בהלוואה לטובת שיפוץ ייתכנו ריביות נמוכות יותר מהלוואת כל מטרה לנסיעה לחו"ל, למשל. להבדלים בריביות הנובעות מסיבת הבקשה למשכנתא יכולות להיות השלכות מרחיקות לכת על הריבית המצטברת שתשלמו לבנק לאורך חיי המשכנתא. </Text>
          <Text>לכן אני עומד לרשותכם בכל נושא או שאלה הקשורה למשכנתא.
        במידה ואתם זקוקים למידע נוסף ניתן לפנות אלי ללא כל התחייבות. התקשרו או השאירו פרטים באתר ונחזור אליכם בהקדם.
        </Text>
        <MainTitle ref={OptionMenu4}>משכנתא פנסיונית (משכנתא לגיל הזהב)</MainTitle>
        <Text>משכנתא פנסיונית מאפשרת קבלת הלוואה כנגד שיעבוד נכס קיים, תוך דחיית ההחזר החודשי על המשכנתא לתקופה ארוכה. כך אפשר ליהנות מרווחה כלכלית באמצעות שימוש בנכס שלכם.  </Text>
        <Title>יתרונות המשכנתא הפנסיונית</Title>
        <List>
            <Listitem>ניתן להשתמש בכספי המשכנתא למגוון צרכים: שמירה על רמת חיים, סיוע לילדים, מימון הוצאה חד פעמית, סגירת התחייבויות, שיפור דיור, שיפוץ ונגישות, מימון מעבר לדיור מוגן או לכל מטרה אחרת לפי בחירתכם </Listitem>
            <Listitem>הבעלות על הנכס נשארת בידיכם, ואפשר להמשיך להתגורר בבית שלכם</Listitem>
            <Listitem>ניתן לדחות את ההחזר החודשי בגין המשכנתא לתקופה ארוכה (עד 20 שנה, לבחירתכם)</Listitem>
          </List>
        <Title>איך ניתן למשוך את גובה המשכנתא שאושרה?</Title>
        <Text>ניתן לקבל את כל ההלוואה בסכום אחד. לחילופין, ניתן למשוך את ההלוואה בחלקים, לנוחיותכם.</Text>
        <Title>איך נקבע סכום המשכנתא ללווים?</Title>
        <Text>גובה המשכנתא שניתן לקבל תלוי בשני פרמטרים:</Text>
        <List>
            <Listitem>גיל הלווים- הגיל המינימלי צריך להיות 60. ככל שהגיל גבוה יותר ניתן לקבל סכום גבוה יותר.
            בכל מקרה לא ניתן לקבל יותר מ- 50% משווי הנכס.
            </Listitem>
            <Listitem>שווי הדירה- שווי הדירה נקבע על ידי שמאי מטעם הגוף המממן. ככל שהשמאי ייתן לדירה ערך גבוה יותר, גובה המשכנתא שתוכלו לקבל יתפח.</Listitem>
          </List>

        <Title>איזה מסלולים יש?</Title>
        <Text>לרוב, אין החזרים חודשיים. ההלוואה ניתנת כנגד שיעבוד נכס ומהווה בטוחה.
        הריבית נצברת על קרן ההלוואה וביום הפירעון הלווים יצטרכו להחזיר את קרן ההלוואה בתוספת ריבית. אך באופן עקרוני קיימות שתי אפשרויות לסילוק ההלוואה:
        </Text>
        <List>
            <Listitem>בלון מלא- במסלול זה אין החזרים חודשיים, קרן ההלוואה תלך ותגדל. ביום הפירעון
            הלווים יצטרכו להחזיר סכום גבוה יותר ממה שלקחו עקב הריבית הנצברת.
            </Listitem>
            <Listitem>בלון חלקי- במסלול זה הלווים משלמים כל חודש רק החזר ריבית (החזר נמוך יחסית).
            ביום הפירעון הם יצטרכו להחזיר את קרן ההלוואה.
            </Listitem>
          </List>
        <Title>איך ניתן לפרוע?</Title>
        <Text>ניתן לפרוע באחת או יותר מהאפשרויות הבאות:</Text>
        <List>
            <Listitem>בעת מכירת הבית- על ידי הלווים.</Listitem>
            <Listitem>כשהלווה הולך לעולמו- היורשים יכולים לפרוע את המשכנתא מכספם או באמצעות מכירת הנכס.</Listitem>
            <Listitem>כשהלווה מסלק את המשכנתא בהחזרי ביניים לאורך השנים. זאת אומרת כל פעם שיש לו סכום
            מסוים, הוא פורע חלק מהמשכנתא וכך מקטין את יתרת הקרן. במהלך חיי המשכנתא ניתן לפרוע את ההלוואה במלואה או חלקה, בכל עת, אין קנסות פירעון מוקדם.
            </Listitem>
          </List>
        <Title>האם יש החזרים חודשיים?</Title>
        <Text>לא בהכרח, תלוי במסלול שיבחר הלווה.</Text>
        <Title>מה היתרון האופרטיבי לעומת משכנתא רגילה?</Title>
        <Text>כיוון שמסוג משכנתא זה לרוב אין החזרים חודשיים, הלווים לא צריכים להציג לבנק כושר השתכרות
        חודשי. ניתן לקבל משכנתא כזו גם אם ללווים אין הכנסה.
        </Text>
        <Title>האם משכנתא הפוכה משתלמת מבחינה מימונית?</Title>
        <Text>מבחינה מימונית עשוי להיות מעט יקרה יותר ממשכנתא רגילה. אך במשכנתא רגילה יצטרכו הלווים לשלם החזרים חודשיים גבוהים הכוללים קרן וריבית. לעומת זאת במשכנתא פנסיונית ניתן אף לוותר על ההחזרים החודשיים. מי שבוחר במשכנתא פנסיונית מתחשב פחות בפאן הכלכלי ויותר מסתכל על הנוחות שמקנה משכנתא כזו.</Text>
        <Title>מהם העלויות הכרוכות?</Title>
        <Text>העלויות הן: דמי פתיחת תיק, עורך דין, יועץ משכנתא ושמאי </Text>
        <Title>מה עם ביטוח משכנתא?</Title>
        <Text>במשכנתא רגילה צריך לעשות שני ביטוחים: ביטוח מבנה וביטוח חיים. במשכנתא פנסיונית הלווה יצטרך לעשות רק ביטוח מבנה שהוא ביטוח זול יחסית ואינו מושפע מגילו של המבוטח וממצב בריאותו כמו ביטוח חיים.</Text>
        <Title> במידה ואתם זקוקים למידע נוסף ניתן לפנות אלי ללא כל התחייבות. התקשרו או השאירו פרטים באתר ואחזור אליכם בהקדם.</Title>

        </MainDiv3>
        </MainDiv2>
      </UnderDivExp>
      <NavBar/>
  </ExpMainDiv>
  )
}

export default Explanations;

import React, { useEffect, useState } from 'react'
import { MainDiv } from './style';
import ButtonAppBar from '../components/WhatsAppComp/WhatsAppComp';
import AdminTable from '../components/AdminTable/AdminTable';
import { getCustomersRequest } from '../services/get_api';
import ChartsBar from '../components/ChartsBar/ChartsBar';

const AdminPage = () => {
  const [customers, setcustomers] = useState(false)

  const FirstgetData = async () => {
    return getCustomersRequest().then((res) => {
      setcustomers(res);
    });
  };


  useEffect(() => {
    if (!customers) {
      FirstgetData();
    }

  }, [])
  

  return (
    <MainDiv>
        <ButtonAppBar/>
        <AdminTable customers={customers}/>
        {/* <ChartsBar/> */}
      
    </MainDiv>
  )
}

export default AdminPage;

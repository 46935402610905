import styled from "styled-components";


export const UnderDiv = styled.div`
  width: 100%;
  height: 1000px;
  background-image: url(background3.svg);
  background-repeat: no-repeat;
  background-size: 190% 130%;
  background-position: 110% 135%;
  flex-direction: column;
  justify-content: space-around;
  margin-top: -33vh;


  @media (max-width: 900px) {
    height: auto;
    margin-top: -20vh;
    background-size: 255% 130%;
    background-position: 130% 105%;

    
}
`;

export const MainDiv1 = styled.div`
  width: 100%;
  height: 650px;
  display: flex;
  padding: 18% 0 0 0;

  @media (max-width: 1300px) {
    flex-direction: column-reverse;
    padding: 30% 0 0 5%;
    height: 100%;

    }
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    padding: 40% 0 0 0;
    height: 80%;

    }
    @media (max-width: 600px) {
        flex-direction: column-reverse;
        padding: 60% 0 0 0;
        height: auto%;
        margin-bottom: 5%;
    
    }
    
    @media (max-width: 400px) {
    padding: 60% 0 0 0;
    
    }
}
`;

export const ImgDiv = styled.div`
    width: 50%;
    @media (max-width: 1200px) {
        margin-top: 5%;
    
    }
    @media (max-width: 900px) {
        width: 100%;
    }

}
`;

export const Background = styled.img`
    width: 100%;
    height: 100%;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @media (max-width: 900px) {
        width: 100%;
        height: 23em;
        }
    @media (max-width: 300px) {
        height: 17em;

    }
}
`;

export const TextDiv = styled.div`
    width: 90%
    height: 100%;
    direction: rtl;
    text-align: left;
    @media (max-width: 900px) {
        height: 150%;
        padding: 0 5%;
    }
}
`;

export const Title = styled.h1`
    font-size: 52px;
    margin-left: 15px;

    @media (max-width: 900px) {
        width: 100%;
        font-size: 35px;
        margin-left: 0;
        
    }
    @media (max-width: 300px) {
        font-size: 25px;
    
    }
}
`;
export const SubTitle = styled.h3`
    font-size: 28px;
    color: #777777;
    @media (max-width: 900px) {
        width: 100%;
        font-size: 20px;
        }
}
`;

export const Button2 = styled.button`
    width: 25%;
    font-size: 15px;
    background-color: #F8FF88;
    border: 1px solid transparent;
    color: #898989;
    border-radius: 5px;
    padding: 2%;
    cursor: pointer;
    margin-top: 10%;

    @media (max-width: 900px) {
        width: 30%;
        height: 12%;
        margin: 12% 0;


    }
    @media (max-width: 300px) {
        width: 30%;
        height: 12%;
        margon-top: -5px;
    
    }
}
`;

export const Button1 = styled.button`
    width: 25%;
    font-size: 15px;
    background-color: #D7EAF9;
    color: #898989;
    border: 1px solid transparent;
    border-radius: 5px;
    margin-left: 5%;
    margin-top: 10%;
    padding: 2%;
    cursor: pointer;

    @media (max-width: 900px) {
        width: 30%;
        height: 12%;
        margin: 12% 2%;


        }
    @media (max-width: 300px) {
        width: 30%;
        height: 12%;


    
    }
}
`;
export const IconsDiv = styled.div`
    display: flex;
    margin-top: 12%;
    direction: ltr;
    color: #777777;
    @media (max-width: 1200px) {
        display: none;

    }

}
`;
export const Linka = styled.a`
    text-decoration: none; 
    color: #898989;

}
`;




import styled from "styled-components";

export const MainDiv = styled.div`
    width: 100%;
    height: 500px;
    text-align: center;
    margin-bottom: 2%;

    @media (max-width: 900px) {
        height: 400px;

    }

`;
export const BackgroundDiv = styled.div`
    width: 100%;
    height: 400px;
    
    background-size: 150% 135%;
    background-position: center; 

    // background-image: linear-gradient(
    //     to bottom, #ffffff, transparent, #ffffff
    //   ), url("story3.svg");
    //   background-repeat: no-repeat;
    
      @media (max-width: 900px) {

        background-size: 250% 135%;
        height: 350px;

    }

`;

export const Title = styled.h2`
    font-size: 20px;
    color: white;
    
    @media (max-width: 900px) {
        font-size: 15px;

    }
`;



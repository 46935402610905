import styled from "styled-components";

export const ContactDiv = styled.div`
    display: none;

  @media (max-width: 900px) {
    display: block;
    width: 90%;
    padding: 0 5%;

    }

}
`;

export const DetailDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;


  @media (max-width: 900px) {

    }

}
`;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;


  @media (max-width: 900px) {

    }

}
`;
export const CircleDiv = styled.div`
  width: 15%;
  height: 60px;
  display: flex;
  justify-content: center;
  background-color: #D7EAF9;
  border-radius: 50%;
  margin: 15px 15px 15px 0;
  align-items: center;

  @media (max-width: 900px) {

    }

}
`;
export const Title = styled.h2`
    font-size: 20px;
    color: #477e75;
    
    @media (max-width: 900px) {
        font-size: 15px;

    }
`;

export const Linka = styled.a`
    text-decoration: none; 
    cursor: pointer;
    color: black;

}
`;
import styled from "styled-components";


export const AudienceDiv = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
 

  }
}
`;
export const CarouselImg = styled.img`
    width: 100%;
    height: auto;
    
    @media (max-width: 900px) {
        width: 100%;
        height: 300px;
    }
`;
export const Title = styled.h2`
    font-size: 20px;
    color: #7084F2;
    margin: 5%;
    text-align: center;
    
    @media (max-width: 900px) {
        font-size: 15px;

    }
`;

export const NextBtn = styled.button`
    color: white;
    background-color: #7084F2;
    border: 0;
    border-radius: 50%;
    width: 4%;
    height: 55px;
    margin-left: 8px;
    align-items: center;


    @media (max-width: 900px) {
        width: 20%;
        height: 55px;

    }
`;
export const BeforeBtn = styled.button`
    color: white;
    background-color: #F8FF88; 
    border: 0;
    border-radius: 50%;
    width: 4%;
    height: 55px;
    margin-left: 8px;
    align-items: center;


    @media (max-width: 900px) {
        width: 20%;
        height: 55px;

    }
`;

import React, { useState, useRef } from 'react'
import { GetDetailsDiv, GetDetailsfFlex, SubTitle1, SubTitle2, SuceessText, TextDiv1, TextDiv2, Title } from './style'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { createTheme , ThemeProvider} from '@mui/material/styles';
import emailjs from '@emailjs/browser';

// import MuiPhoneNumber from 'mui-phone-number';


const GetDetails = (props) => {
  const sendemail = (e) => {
    e.preventDefault();
      emailjs
        .sendForm(
          "service_hde5grj",
          "template_ibxg0nh",
          form.current,
          "gjSYNc6Kn8BKjrYOG"
        )
        .then(
          (result) => {
            console.log(result);
          },
          (error) => {
            console.log(error);
          }
        );
  }

    const [name, setname] = useState('')
    const [phonenumber, setphonenumber] = useState('')
    const [email, setemail] = useState('')
    const form = useRef();

    let userdata = {
      name: name,
      phonenumber: phonenumber,
      email: email
    }

    const theme =
    createTheme({
      direction: 'rtl',
    });

    const cacheRtl = createCache({
      key: 'muirtl',
      stylisPlugins: [prefixer, rtlPlugin],
    });

    const handleSubmit = async (e) => {
      e.preventDefault();
      await props.newRequestAPI(userdata).then(res => {
        if(res === true){
          return sendemail(e)
        }else{
          return false;
        }
      })
    }

  return (
    <GetDetailsDiv stylisPlugins={[rtlPlugin]}> 
      {/* <TextDiv1>
       <SubTitle1>?זוגות צעירים לפני רכישת נכס</SubTitle1>
      <SubTitle1> נתקלתם בבעיה בלקיחת המשכנתא</SubTitle1>
      </TextDiv1> */}
      <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
      <TextDiv2 >
      {/* <SubTitle2>השאר פרטים לייעוץ ראשוני ללא עלות</SubTitle2> */}
      {/* <MuiPhoneNumber defaultCountry={'es'} onChange={''}/> */}
      <Title >השאירו פרטים ונחזור אליכם בהקדם:</Title>
      <form ref={form} onSubmit={handleSubmit}>
        <TextField
            id="outlined-multiline-flexible"
            label="שם מלא"
            name='name'
            multiline
            size="small"
            sx={{ margin: '2%', rotate: 'revert', background: 'white', borderRadius: '10px'}}
            maxRows={4}
            onChange={(e) => setname(e.target.value)}
  
          />
      <TextField 
          id="outlined-multiline-flexible"
          label="טלפון"
          name='phonenumber'
          multiline
          size="small"
          sx={{ margin: '2%', rotate: 'revert', background: 'white', borderRadius: '10px'}}
          maxRows={4}
          onChange={(e) => setphonenumber(e.target.value)}

        />
      <TextField
          id="outlined-multiline-flexible"
          label="אימייל"
          name='email'
          multiline
          size="small"
          sx={{ margin: '2%', rotate: 'revert', background: 'white', borderRadius: '10px'}}
          maxRows={4}
          onChange={(e) => setemail(e.target.value)}

        /> 
        <GetDetailsfFlex>
          <Button type='submit' sx={{color: "white !important", border: '1px silver solid' , margin: '2%', padding: '1%',  height: '20%', background: '#7084F2', borderRadius: '10px', display: props.success && 'none'}} >שליחה</Button>
          <SuceessText style={{display: props.success && 'block'}}>בקשתך התקבלה בהצלחה!</SuceessText>
        </GetDetailsfFlex>
        </form>

        </TextDiv2>
        
        </ThemeProvider>
      </CacheProvider>
   </GetDetailsDiv>
  )
}

export default GetDetails

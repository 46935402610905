import styled from "styled-components";
import { LineChart } from '@mui/x-charts/LineChart';


export const ChartsDiv = styled.div`
    display: flex;
    justify-content: center;
    direction: rtl;
    @media (max-width: 900px) {
        display: block;
        width: 100%;
    }
`;

export const DataDiv = styled.div`
    width: 30%;
    margin: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @media (max-width: 900px) {
        width: 100%;
    }
`;

export const DataText = styled.h4`
    direction: rtl !important;
`;


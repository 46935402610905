import React from 'react'
import { CircleDiv, ContactDiv, DetailDiv, TextDiv, Title } from './style'
import PublicIcon from '@mui/icons-material/Public';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Linka } from './style';

const ContactForMobile= () => {
  return (
    <ContactDiv>
        <DetailDiv><CircleDiv><PublicIcon /></CircleDiv> נתניה, השרון, ישראל</DetailDiv>
        <DetailDiv><CircleDiv><MailOutlineIcon /> </CircleDiv>  <Linka target="_blank" href="mailto:inbox.mortgage@gmail.com?body=היי אני רוצה הצעת מחיר למשכנתא שלי">inbox.mortgage@gmail.com</Linka></DetailDiv>
    </ContactDiv>
  )
}

export default ContactForMobile

import React from 'react'
import { Linka, Links, LinksDiv, Logo, NavbarDiv, NavbarText, TextDiv } from './style';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import YouTubeIcon from '@mui/icons-material/YouTube';

const NavBar = () => {

  const TikTokIcon = ({ color = "#000000" }) => {
    return (
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 45 45"
        width="27px"
        height="27px"
      
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };

  return (
    <NavbarDiv>
        <TextDiv>
        <NavbarText>
            <Linka target="_blank" href='https://www.instagram.com/amit.mesilati?igsh=MWJrNjVvZzBzNmM4Zg%3D%3D&utm_source=qr'><InstagramIcon sx={{margin: '0 20px' , cursor: 'pointer'}} /></Linka>
            {/* <Linka target="_blank" href="mailto:inbox.mortgage@gmail.com?body=היי אני רוצה הצעת מחיר למשכנתא שלי"><MailOutlineIcon sx={{margin: '0 20px' , cursor: 'pointer'}} /></Linka> */}
            <Linka target="_blank" href='http://linkedin.com/in/amit-mesilati-b490261ab'><LinkedInIcon sx={{margin: '0 20px', cursor: 'pointer'}}/></Linka>
            <Linka target="_blank" href='https://www.facebook.com/AMITMESI32?mibextid=LQQJ4d'><FacebookIcon sx={{margin: '0 20px', cursor: 'pointer'}}/></Linka>
            <Linka target="_blank" href='https://youtube.com/@amitmesi?si=A_Mv4K2-5ihnMs52'><YouTubeIcon sx={{margin: '0 20px', cursor: 'pointer'}}/></Linka>
            <Linka target="_blank" href={'https://wa.me/972505555239?text=היי אני רוצה הצעת מחיר למשכנתא שלי'}><WhatsAppIcon sx={{margin: '0 20px 0 40px', cursor: 'pointer'}}/></Linka>
            <Linka target="_blank" href='https://www.tiktok.com/@amitmesi?_t=8oywGWTt2et&_r=1'><TikTokIcon color="white" sx={{ cursor: 'pointer'}}/></Linka>
          {/* <TikTokIcon color="white"/> */}
        </NavbarText>
          <NavbarText style={{fontSize: '18px', fontWeight: '500', textDecoration: 'underline'}}>
          קישורים חיצוניים לנוחיותכם
          </NavbarText>
          <LinksDiv>
          <Links target="_blank" href='https://www.gov.il/he/service/register_for_discounted_housing_lottery'>הרשמה למחיר למשתכן</Links>
          <Links target="_blank" href='https://mekarkein-online.justice.gov.il/voucher/main' style={{marginTop: '15px'}}>הפקת נסח טאבו</Links>

          </LinksDiv>
        </TextDiv>
        <Logo src='/yellowlogo.png'/>
        <NavbarText>
            @ כל הזכויות שמורות | עמית יעוץ משכנתאות ופתרונות מימון 
        </NavbarText>
    </NavbarDiv>
  )
}

export default NavBar;

import React from 'react'
import { AudienceDiv, BeforeBtn, CarouselImg, NextBtn, Title } from './style';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const NewAudience = () => {
    
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
            <div className="blockp-3bg-slate-300  gap-4 flex justify-end 
            items-center w-full">
              <NextBtn className='block p-3 bg-slate-300' onClick={() => 
              previous()}> <NavigateNextIcon style={{width: '35px', height: '35px'}}/></NextBtn>
              <BeforeBtn onClick={() => next()}><span className='block p-3 bg-slate-300' ><NavigateBeforeIcon style={{width: '35px', height: '35px'}}/></span></BeforeBtn>
           </div>
        
         );
       };

    const sliderImageUrl = [
        //First image url
        {
            url:
            "/NewDoc1.svg"
        },
        {
          url:
          "/NewDoc2.svg"
        },
        //Second image url
        {
          url:
          "/NewDoc3.svg"
        },
        //Third image url
        {
          url:
          "/NewDoc4.svg"
        },
      
        //Fourth image url
        {
          url:
          "/NewDoc5.svg"
        },
        {
          url:
          "/NewDoc6.svg"
        },
        {
          url:
          "/NewDoc7.svg"
        },
   
      ];
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

  return (
    <AudienceDiv>
           <Title>
            למי השירות מתאים?
           </Title>
          <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile", ]}
        arrows={false} 
        renderButtonGroupOutside={true} 
        autoPlaySpeed={8000}
        customButtonGroup={<ButtonGroup />}
        itemClass="carousel-item-padding-40-px"
 
        >
        {sliderImageUrl.map((imageUrl, index) => {
          return (
            <div key={index}>
              <CarouselImg src={imageUrl.url} alt="image" />
              
            </div>
          );
        })}


      </Carousel>
      
    </AudienceDiv>
  )
}

export default NewAudience;

import styled from "styled-components";

export const NavbarDiv = styled.div`
    width: 100%;
    height: auto;
    position: absolute;
    bottom: o;
    background-color: #7084F2;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const NavbarText = styled.p`
    font-size: 10px;
    color: white;
    margin-top: 25px;

`;

export const TextDiv = styled.div`
    height: 12%;


`;

export const LinksDiv = styled.div`
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 25px;


`;


export const Linka = styled.a`
    text-decoration: none; 
    color: white;
    cursor: pointer;

}
`;

export const Links = styled.a`
    text-decoration: none; 
    color: white;
    cursor: pointer;
    width: 100%;

}
`;


export const Logo = styled.img`
  display: inline-block;
  color: #BF4F74;
  width: 50px;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  margin-right: 1%;
  margin-top: 0.2%;
  height: 5%;

  @media (max-width: 900px) {
  }

`;
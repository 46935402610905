import styled from "styled-components";

export const LoadingDiv = styled.div`
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to bottom right, #7084F2, #F8FF88);
    align-items: center;
    display: flex;
    justify-content: center;
    @media (max-width: 900px) {
        overflow: hidden;
    }

`;

export const LoadingImg = styled.div`
    align-items: center;

    @media (max-width: 900px) {
    }

`;



import React, { useState } from 'react'
import { AppBar, ButtonN, ButtonsDiv, Logo, PhoneButton } from './style'
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MenuIcon from '@mui/icons-material/Menu';


const AppNavBar = (props, ref) => {
    const [anchorEl, setAnchorEl] = useState('');
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = async (e) => {
      if(!e.target){
        console.log(e.target)
      if(window.location.toString().includes("exp")){
        props.scrollingFunc(e)
      }
      window.localStorage.setItem('scrollTo', e);
      setAnchorEl(null);
      navigate('/exp')
      }else{
        setAnchorEl(null);
      }
    };

    const navigate = useNavigate();
    const handleClickLogo = () => {
        navigate('/');
    };


  return (
    <AppBar>
        <ButtonsDiv>

          <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="fade-menu"
        className='menuBar'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => {
          return handleClose(1)
           }}>מיחזור משכנתא</MenuItem>
        <MenuItem onClick={() => {
          return handleClose(2)
           }}>משכנתא לרכישת דירה מקבלן/ יד 2</MenuItem>
        <MenuItem onClick={() => {
          return handleClose(3)
           }}>משכנתא לכל מטרה</MenuItem>
        <MenuItem onClick={() => {
          return handleClose(4)
           }}>משכנתא פנסיונית (משכנתא לגיל הזהב) </MenuItem>
      </Menu>



        {/* <Button>התחברות</Button> */}
        {/* <ButtonN onClick={props.handleClickAboutme}>אודות</ButtonN> */}
        <PhoneButton href="tel:0505555239"><PhoneIcon/> צור קשר  </PhoneButton>
        </ButtonsDiv>
        <Logo src='/amitnewLOGO.png' alt="image" onClick={handleClickLogo} />
    </AppBar>
  )
}

export default AppNavBar

import styled from "styled-components";


export const WhatsUpDiv = styled.a`
  display: none ;

  @media (max-width: 900px) {
    width: 50px;
    height: 50px;
    position: fixed;
    left: 0;
    bottom: 10%;
    cursor: pointer;
    z-index: 999;
  }
`;


export const WhatsUp = styled.img`

  @media (max-width: 900px) {
    width: 100%;
  }  
`;  


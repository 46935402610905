import React, { forwardRef, useState } from 'react'
import { BackgroundDiv, MainDiv, Title } from './style';
import GetDetails from '../GetDetails/GetDetails';
import { postRequest } from '../../services/get_api';

const ContactUs = (props, ref) => {
    const [success, setsuccess] = useState(false)

    const newRequestAPI = async (props) => {
        return postRequest(props).then(res => {
          if(res.status == 'error'){
            return false
          }else{
            setsuccess(true)
            return true
          }
        })
    }
    
  return (
    <MainDiv >
        <p ref={ref}></p>
    <BackgroundDiv  >
        <Title > ---</Title>
      <GetDetails newRequestAPI={newRequestAPI} success={success}/>
      
    </BackgroundDiv>
    </MainDiv>
  )
}

export default forwardRef(ContactUs);

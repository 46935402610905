import styled from "styled-components";


export const MainDiv = styled.div`
  width: 100%;
  height: auto;
  position: relative;

`;

export const ExpMainDiv = styled.div`
  width: 100%;
  height: auto;

`;

export const ErrorText = styled.p`
  color: red;
  font-size: 10px;

`;

export const Background = styled.img`
    width: 100%;
    height: 100%;

    @media (max-width: 900px) {
        width: 100%;
        height: 23em;
        }
    @media (max-width: 300px) {
        height: 17em;

    }
}
`;


export const MainTitle = styled.h2`
    font-size: 28px;
    font-weight: 500;
    color: #7084F2;

    @media (max-width: 900px) {
      font-size: 26px;
      

    }
}
`;
export const Title = styled.h3`
    font-size: 22px;
    font-weight: 500;

    @media (max-width: 900px) {
      font-size: 20px;
      

    }
}
`;

export const List = styled.ul`
    display: block;
    list-style-type: disc;
    margin-top: 0;
    margin-bottom: 15px;
    margin-left: -20px !important;
    margin-right: 0;
    font-size: 19px;
    line-height: 25px;

    @media (max-width: 900px) {
      font-size: 17px;
    }
}
`;

export const Listitem = styled.li`
 
}
`;

export const Text = styled.h5`
    font-size: 19px;
    margin: 15px 0;
    line-height: 25px;



    @media (max-width: 900px) {
      font-size: 17px;
    }
}
`;


export const MainDiv2 = styled.div`
  width: 90%;
  display: flex;
  padding: 24% 0;
  margin: 0 5%;
  flex-direction: column;
  flex-wrap: wrap;

  @media (max-width: 1300px) {
    flex-direction: column;
    padding: 30% 0 0 0;

    }
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 40% 0 0 0;


    }
    @media (max-width: 600px) {
        flex-direction: column;
        padding: 60% 0 0 0;
        margin-bottom: 5%;
    
    }
    
    @media (max-width: 400px) {
    padding: 60% 0 0 0;
    
    }
}
`;
export const MainDiv3 = styled.div`
  margin: 0 15% 0% 0%;

  @media (max-width: 900px) {
    margin: 0 5%;

  }
}
`;

export const UnderDivExp = styled.div`
width: 100%;
height: auto;
background-image: url(background3.svg);
background-repeat: no-repeat;
background-size: 190% 130%;
background-position: 160% 195%;
flex-direction: column;
justify-content: space-around;
margin-top: -33vh;


@media (max-width: 900px) {
  height: auto;
  margin-top: -20vh;
  background-size: 255% 130%;
  background-position: 130% 205%;

  
}
`;




import React from 'react'
import MainPage from './pages/MainPage'
import { BrowserRouter, Routes, Route, Switch, Redirect,} from "react-router-dom";
import About from './pages/About';
import AdminLogin from './pages/AdminLogin';
import AdminPage from './pages/AdminPage';
import Explanations from './pages/Explanations';

const App = () => {
  const user = JSON.parse(localStorage.getItem('user'))

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />}/>
        <Route index element={<MainPage />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<MainPage />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/data" element={user && user.isAdmin ? <AdminPage /> : <AdminLogin /> }/>
        <Route path="/exp" element={<Explanations />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App


import React from 'react'
import { Background, Button1, Button2, IconsDiv, ImgDiv, Linka, MainDiv1, SubTitle, TextDiv, Title, UnderDiv } from './style';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const UnderBackground = (props) => {
  return (
    <UnderDiv>
      <MainDiv1>
        <ImgDiv>
            <Background src='/Presentation2.svg' />
        </ImgDiv>
        <TextDiv>
            <Title> עמית | יעוץ משכנתאות ופתרונות מימון</Title>
            <SubTitle>ייעוץ וליווי אישי בכל תחומי המשכנתא <DoneAllIcon/></SubTitle>
            <SubTitle>חיסכון מקסימאלי <DoneAllIcon/></SubTitle>
            <SubTitle>מותאמת לצרכים שלכם <DoneAllIcon/></SubTitle>
            {/* <SubTitle> ----</SubTitle> */}
            <Button1 onClick={props.handleClickAboutme}>הכר אותי</Button1>
            <Button2 onClick={props.handleClickDetails}>השאר פרטים</Button2>
        <IconsDiv>
            <Linka target="_blank" href='https://www.instagram.com/amit_mort?igsh=cXJmNzlncjNyOHQw'><InstagramIcon sx={{margin: '0 20px' , cursor: 'pointer'}} /></Linka>
            <Linka target="_blank" href="mailto:inbox.mortgage@gmail.com?body=היי אני רוצה הצעת מחיר למשכנתא שלי"><MailOutlineIcon sx={{margin: '0 20px' , cursor: 'pointer'}} /></Linka>
            <Linka target="_blank" href='http://linkedin.com/in/amit-mesilati-b490261ab'><LinkedInIcon sx={{margin: '0 20px', cursor: 'pointer'}}/></Linka>
            <Linka target="_blank" href='https://www.facebook.com/AMITMESI32?mibextid=LQQJ4d'><FacebookIcon sx={{margin: '0 20px', cursor: 'pointer'}}/></Linka>
            <Linka target="_blank" href={'https://wa.me/972505555239?text=היי אני רוצה הצעת מחיר למשכנתא שלי'}><WhatsAppIcon sx={{margin: '0 20px 0 0', cursor: 'pointer'}}/></Linka>

        </IconsDiv>

        </TextDiv>
      </MainDiv1>
    </UnderDiv>
  )
}

export default UnderBackground;

import styled from "styled-components";


export const CarouselDiv = styled.div`
    width: 80%;
    margin: 10% 0 5% 10%;
    text-align: center;
    color: #477e75;
    
    @media (max-width: 1300px) {
        width: 90%;
        margin-top: 80%;
        margin: 40% 0 20% 5%;
        
    }
    @media (max-width: 900px) {
        width: 90%;
        margin-top: 80%;
        margin: 10% 0 20% 5%;
        
    }
`;

export const CarouselImg = styled.img`
    width: 200px;
    height: 80px;
    
    @media (max-width: 900px) {
        width: 100px;
        height: 50px;
        margin-bottom: 30px;
        z-index; 9;

    }
`;
export const Title = styled.h2`
    font-size: 20px;
    color: #7084F2;
    
    @media (max-width: 900px) {
        font-size: 15px;

    }
`;

import React from 'react'
import { CarouselDiv, CarouselImg, Title } from './style';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const CarouselBanks = () => {

      const sliderImageUrl = [
        //First image url
        {
          url:
            "/mizrahi.svg"
        },
        {
          url:
          "/Poalim.svg"
        },
        //Second image url
        {
          url:
          "/Leumi.svg"
        },
        //Third image url
        {
          url:
          "/Beinleumi.svg"
        },
      
        //Fourth image url
        {
          url:
          "/Mercantile.svg"
        },
        {
          url:
          "/Discount.svg"
        },
        {
          url:
          "/mimun-yahsir.png"
        }
      ];
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
  return (
    <CarouselDiv>
        <Title>עובד עם כל הבנקים</Title>
          <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        autoPlaySpeed={5000}
        removeArrowOnDeviceType={["tablet", "mobile", ]}
        arrows={false}
        itemClass="carousel-item-padding-40-px"
 
        >
        {sliderImageUrl.map((imageUrl, index) => {
          return (
            <div key={index}>
              <CarouselImg src={imageUrl.url} alt="movie" />
              
            </div>
          );
        })}


      </Carousel>
      
    </CarouselDiv>
  )
}

export default CarouselBanks;

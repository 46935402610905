import { useState ,useEffect } from 'react';
import ButtonAppBar from '../components/WhatsAppComp/WhatsAppComp'
import { ErrorText, MainDiv } from './style'
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { adminLogin, getCustomersRequest } from '../services/get_api';
import { useNavigate } from 'react-router-dom';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          עמית משכנתאות
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  const theme = createTheme({
    direction: 'rtl',
  });

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
  
  

const AdminLogin = () => {
    const navigate = useNavigate()
    const [phonenumber, setphonenumber] = useState('')
    const [password, setpassword] = useState('')
    const [error, seterror] = useState('')

    const user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
      if (!user) {
       navigate('/admin')
      }
    }, []);

    let data = {
        phonenumber: phonenumber,
        password: password
    }
    
    const loginRequestAPI = async () => {
        return adminLogin(data).then(res => {
          if(res && res.status == "error"){
            seterror(res.error)
          }else{
            seterror("")
            return getCustomersRequest().then((res) => {
              if(res.error){
                seterror(res.error)
              }else{
                navigate('/admin/data')
                window.location.reload(); 

              }
              })
          }
        })
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        loginRequestAPI()
      }
    }


  return (
    <MainDiv>
        <ButtonAppBar/>
        <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'rgb(71 126 117)' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            התחבר
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              label="טלפון"
              name="phone"
              autoComplete="phone"
              autoFocus
              size="small"
              onChange={(e) => setphonenumber(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}

            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="סיסמה"
              type="password"
              id="password"
              autoComplete="current-password"
              size="small"
              onChange={(e) => setpassword(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}

            />
            <ErrorText>{error}</ErrorText>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2}}
              onClick={loginRequestAPI}
            >
              הכנס
            </Button>
            <Grid container>
              <Grid item xs>
              {/* <Link href="#" variant="body2">
                 שכחת סיסמה?
                </Link>; */}
                  </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      </ThemeProvider>
    </CacheProvider>
    </MainDiv>
  )
}

export default AdminLogin;

import React, { forwardRef } from 'react'
import { AboutMeBtext, AboutMeDiv, AboutMeText, AboutMeTitle, FlexDiv, Logo, Picture, WhoamiDiv } from './style';

const Whoami = (props, ref) => {
  return (
    <WhoamiDiv ref={ref}>
        <FlexDiv >
            <Picture src='/Presentation3.svg' alt="image" />
            <AboutMeDiv>
              {/* <AboutMeTitle ref={ref}>למה לבחור בי?</AboutMeTitle> */}
              <Logo src='/whitebackground.jpeg'/>
     
                <AboutMeText>
                נעים מאוד, עמית מסילתי, בוגר תואר ראשון B.A במנהל עסקים עם התמחות במימון, תפקידי האחרון היה כבנקאי משכנתאות באחד מהבנקים הגדולים בישראל דבר זה גרם לי לצבור הרבה קולגות וידע רב בתחום.
                ליוויתי עשרות זוגות צעירים ומבוגרים, יחידים ובעלי עסקים בתהליך.
                ככה שיש לכם על מי לסמוך.
                </AboutMeText>
                <AboutMeText>
                בהחלטה חשובה כמו המשכנתא שלכם אל תתפשרו, קחו יועץ שילווה אותכם באופן אישי בכל תחומי המשכנתא.
                להחלטה כבדת משקל כמו זו יש השפעה עתידית בהיבט הכלכלי והמודרני של כל משק בית בישראל.
                </AboutMeText>
                <AboutMeText>
                אם להיות כנים משכנתא לתקופה של 30-20 שנה זה משהו שלא בא לנו לחשוב עליו במיוחד שהריביות והמדדים במשק משתנים בקצב מסחרר.
                </AboutMeText>
                <AboutMeText>
                 ב <AboutMeBtext>5 שאלות קצרות</AboutMeBtext>
                 אדע לאפיין לכם את אופי העסקה ומהותה, אתאים לכם אסטרטגיה נכונה שתמקסם את התחרות מול הבנקים הגדולים פעולה זאת תניב חיסכון מקסימלי בהחזר החודשי, 
                לאחר מכן אתאים את התמהיל הנכון ביותר והמדוייק ביותר לתקופת ההלוואה והכי חשוב מותאמת לצרכים שלכם.    
                </AboutMeText>
                <AboutMeText>
                הפעולות הללו יגרמו לכם לחסוך המון זמן כוח וכסף.
                </AboutMeText>
                <AboutMeText>
                לכן אל תתפשרו על פחות
                </AboutMeText>
                <AboutMeText>
                תנו לי לעשות את זה עבורכם!
                </AboutMeText>
            
                <AboutMeText style={{color: '#7084F2'}}>
                  Make your dream home a reality 
                </AboutMeText>
 
            </AboutMeDiv>
        </FlexDiv>
    </WhoamiDiv>
  )
}

export default forwardRef(Whoami);

import styled from "styled-components";



export const TableDiv = styled.div`
  cursor: pointer;
  text-align: center;
  width: 60%;
  padding: 5% 20%;
  @media (max-width: 900px) {
    width: 90%;
    padding: 5%;
  }
`;

export const Title = styled.h2`
    font-size: 25px;
    @media (max-width: 900px) {
    font-size: 20px;
    }
    direction: rtl;
    font-weight: 500;

`;
import styled from "styled-components";


export const WhoamiDiv = styled.div`
  width: 100%
  padding: 0 7%;
  background-image: url(BulbBack3.svg);
  background-repeat: no-repeat;
  background-size: 54% 125%;
  background-position: -20% 50%;


  // background-position: right; 
  @media (max-width: 1000px) {
    margin-top: 15%;
    background-position: 60% 90%;
    background-size: 170% 165%;

  }
}
`;

export const FlexDiv = styled.div`
  display: flex;
  height: 96%;
  text-align: center;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

export const Picture = styled.img`
  width: 36%;
  height: 500px;
  @media (max-width: 900px) {
    width: 100%;
    height: 340px;
}

`;

export const AboutMeDiv = styled.div`
  width: 75%;
  margin: 0% 8% 1% 8%;
  text-align: left;
  @media (max-width: 900px) {
    width: 77%;
    margin: 0% 1% 1% 1%;
    text-align: center;

  }

`;
export const AboutMeTitle = styled.h6`
  font-size: 20px;
  margin: 1%;
  color: #477e75;
  @media (max-width: 900px) {
    font-size: 14px;
    }
`;

export const AboutMeText = styled.p`
  font-size: 17px;
  margin: 1%;
  @media (max-width: 900px) {
    font-size: 14px;
    }
`;

export const AboutMeBtext = styled.span`
  font-size: 18px;
  margin: 1%;
  font-weight: 500;
  display: inline;
  @media (max-width: 900px) {
    font-size: 12px;
  }

`;

export const Logo = styled.img`
  width: 10%;
  height: 50px;
  @media (max-width: 900px) {
    width: 10%;
    height: 70px;
  }
}

`;



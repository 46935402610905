import * as React from 'react';
import { WhatsUp, WhatsUpDiv } from './style';



export default function WhatsAppComp(props) {


    return (
        <WhatsUpDiv className={props.scrolling && 'whatsUppMobile'} target="_blank" href={'https://wa.me/972505555239?text=היי אני רוצה הצעת מחיר למשכנתא שלי'}>   
          <WhatsUp src='/WhatsApp.png' alt="image"  />
        </WhatsUpDiv>
    );
  }
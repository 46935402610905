import styled from "styled-components";


export const AppBar = styled.div`
  width: 100%;
  height: 12vh;
  background: transparent;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    height: 8vh;
    background: white;
    position: fixed;
    top: 0;
    z-index: 999;    
    }

}
`;

export const ButtonsDiv = styled.div`
  width: 25%;
  display:flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 900px) {
    width: 45%;

}
`;


export const Logo = styled.img`
  display: inline-block;
  color: #BF4F74;
  width: auto;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  margin-right: 1%;
  margin-top: 0.2%;
  height: 50%;

  @media (max-width: 900px) {
    height: 60%;
  }

`;

export const ButtonN = styled.button`
    font-size: 20px;
    border: 0;
    color: #898989;
    background: transparent;
    align-items: center;
    cursor: pointer;

    @media (max-width: 900px) {
        font-size: 15px;
        color: black;

    }

`;

export const PhoneButton = styled.a`
    font-size: 20px;
    border: 0;
    color: #898989;
    background: transparent;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    cursor: pointer;
    text-decoration: none;


    @media (max-width: 900px) {
        font-size: 15px;
        color: black;

    }

`;
import React from 'react'
import { IframeDiv, MainDiv, Title } from './style';

const Podcast = () => {
  return (
    <MainDiv>
        <Title>
           ערוץ היוטיוב שלנו
        </Title>
      <IframeDiv src="https://www.youtube.com/embed/ReYpbTn_AoU" title="איך לוקחים משכנתא בשיטה החדשה ומה היתרונות בשרותי יעוץ." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></IframeDiv>
      <IframeDiv src="https://www.youtube.com/embed/q85H8mIaQaU" title="שיטות והסברים בלקיחת משכנתא מפי הבנקאי לשעבר עמית מסילתי חלק ב" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></IframeDiv>    
    </MainDiv>
  )
}

export default Podcast;
import React, { useEffect, useRef, useState } from 'react'
import { MainDiv } from './style'
import NavBar from '../components/NavBar/NavBar'
import Whoami from '../components/Whoami/Whoami'
import AppNavBar from '../components/AppNavBar/AppNavBar'
import UnderBackground from '../components/UnderBackground/UnderBackground'
import CarouselBanks from '../components/CarouselBanks/CarouselBanks'
import ContactForMobile from '../components/ContactForMobile/ContactForMobile'
import ContactUs from '../components/ContactUs/ContactUs'
import NewAudience from '../components/NewAudience/NewAudience'
import WhatsAppComp from '../components/WhatsAppComp/WhatsAppComp'
import Loading from '../components/Loading/Loading'
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import Podcast from '../components/Podcast/Podcast'


const MainPage = () => {

  const Detailsref = useRef();
  const Aboutmeref = useRef();
  const [scrolling, setScrolling] = useState(false);
  const [loading, setLoading] = useState(true)

  const handleClickDetails = () => {
    if (Detailsref.current) {
      Detailsref.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickAboutme = () => {
    if (Aboutmeref.current) {
      Aboutmeref.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onScroll = () => {
    let currentPosition = window.scrollY;
    if (currentPosition > 1100) {
      setScrolling(true);
    } 
  }

  useEffect(()=>{
    window.addEventListener("scroll", onScroll);
    setTimeout(() => setLoading(false), 1500)
    
  }, [Detailsref])

  if (loading) {
    // return <Loading/>
  }
  

  return (
    <MainDiv >
      <AppNavBar handleClickAboutme={handleClickAboutme}/>
      <UnderBackground handleClickDetails={handleClickDetails} handleClickAboutme={handleClickAboutme}/>
      <CarouselBanks/>

        <Whoami ref={Aboutmeref}/>
        <ContactUs ref={Detailsref}/>
      <ContactForMobile/>
      <NewAudience/>
      <Podcast/>
      <WhatsAppComp scrolling={scrolling}/>
      <Analytics/>
      <SpeedInsights/>
        <NavBar/>
    </MainDiv>
  )
}

export default MainPage;

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableDiv, Title } from './style';
import Sheet from '@mui/joy/Sheet';



export default function AdminTable(props) {

    return (
    <TableDiv>
    <TableContainer component={Paper}>
    <Title>-לקוחות שהשאירו פרטים</Title>
    <Sheet
        sx={{
          '--TableCell-height': '40px',
          // the number is the amount of the header rows.
          '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
          height: props.customers.length > 5 ? 300 : 'auto',
          overflow: 'auto',
          background: (theme) =>
            `linear-gradient(${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 50% 0,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 50% 100%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
          backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'local, local, scroll, scroll',
          backgroundPosition:
            '0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%',
          backgroundColor: 'background.surface',
        }}
      >
      <Table aria-label="simple table" stickyHeader >
        <TableHead>
          <TableRow>
            {/* <TableCell align="right">צקבוקס</TableCell> */}
            <TableCell align="right">שם הלקוח</TableCell>
            <TableCell align="right">מספר טלפון</TableCell>
            <TableCell align="right">אימייל</TableCell>
            <TableCell align="right">שעה ותאריך</TableCell>
          </TableRow>
        </TableHead>
        <TableBody> 
          {props.customers && props.customers.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.phonenumber}</TableCell>
              <TableCell align="right">{row.email ? row.email : "---"}</TableCell>
              <TableCell align="right">{new Date(row.time).toLocaleTimeString()+' -  '+ new Date(row.time).toLocaleDateString()}</TableCell>
            </TableRow>
          )).reverse()}
        </TableBody>
      </Table>
      </Sheet>
    </TableContainer>
    </TableDiv>
  );
}

import styled from "styled-components";

export const GetDetailsDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;
  opacity: 1;
  
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
    @media (max-width: 900px) {
      height: 100%;
      width: 100%;
      flex-direction: column;
      }
}
`;

export const TextDiv1 = styled.div`
 
display: flex;
flex-direction: column;
direction: rtl;
width: 70%;


@media (max-width: 900px) {
  width: 100%;
  align-items: center;

  }
`;

export const TextDiv2 = styled.div`
display: flex;
direction: rtl;
flex-direction: column;
justify-content: space-around;
width: 80%;
height: 80%;
border: 1px white solid;
background-image: url("Backcontact.jpg");
    background-repeat: round;
border-radius: 10px;
padding: 1%;
margin-top: 5%;
direction: ltr;

  @media (max-width: 900px) {
    width: 90%;
    height: 100%;
    align-items: center;
    margin: 10% 0;
    padding: 5%;
  }

`;


export const SubTitle1 = styled.h2`
    color: white;
    font-size: 40px;
    @media (max-width: 900px) {
    font-size: 20px;
    }
    direction: rtl;
    font-weight: 500;
    margin: 5% 10%;

`;

export const SubTitle2 = styled.h2`
    color: black;
    font-size: 22px;
    font-weight: 800;
    direction: rtl;
    @media (max-width: 900px) {
    font-size: 20px;
    }

`;


export const GetDetailsfFlex = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  direction: rtl;
  color: #898989 !important;

`;

export const SuceessText = styled.div`
  text-align: center;
  align-items: center;
  display: none;
  direction: ltr;
  color: #898989;

`;

export const Title = styled.h2`
    font-size: 20px;
    color: #7084F2;
    
    @media (max-width: 900px) {
        font-size: 17px;

    }
`;